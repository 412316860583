<template>
  <div
    class="bg"
    :style="`background-image: url(${require('@/assets/home-bg.jpg')})`"
  >
    <div class="overlay" />
    <div
      :class="[
        'home-golf',
        'main-layout-min-height',
        'has-news'
      ]"
    >
      <div class="bg-cover" />
      <div class="content">
        <div class="first">
          <h1 class="title">
            Adventure Golf hat nicht viel mit dem „altbekannten“ Minigolf zu tun und ist dem „großen Golf“ sehr ähnlich.
          </h1>
          <h3 class="sub-title">
            Unsere Anlage verkörpert unsere Region mit unserer Philosophie - dem regionalen Sport.
          </h3>
          <button
            class="base-button"
          >
            ERLEBE EINE TOLLE ZEIT - JETZT RESERVIEREN
          </button>
          <div class="features">
            <div class="item">
              <div class="detail">
                <h5 class="big">
                  18
                </h5>
                <p class="small">
                  Bahnen
                </p>
              </div>
            </div>
            <div class="item">
              <div class="detail">
                <h5 class="big">
                  3.500
                </h5>
                <p class="small">
                  m2
                </p>
              </div>
            </div>
            <div class="item">
              <div class="detail">
                <h5 class="big">
                  100%
                </h5>
                <p class="small">
                  Fun
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="second">
        Open time
      </div> -->
        <div
          v-if="filteredNewsTicker.length"
          class="news-ticker"
        >
          <div class="news-ticker-title">
            <span style="color: #000;">+++</span> <span style="color: #000;">NEWS</span>TICKER<span style="color: #000;"> +++</span>
          </div>
          <div class="body">
            <div class="address">
              <p
                v-for="(item, index) in filteredNewsTicker"
                :key="`${item.identification}${index}`"
                class="information ql-editor"
                v-html="item.information"
              />
            </div>
          </div>
        </div>
        <div v-else>
          <h5>
            NO Data
          </h5>
          <router-link to="/admin/news">
            GO BACK
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/pages/admin-news-preview-golf.scss";
</style>

<script>
import 'quill/dist/quill.core.css';
import { mapState } from 'vuex';
require('@/plugins/datejs/date-de-DE');

export default {
  computed: {
    ...mapState('admin', {
      filteredNewsTicker: 'previewNews',
    }),
  },
};
</script>
