var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg",style:(`background-image: url(${require('@/assets/home-bg.jpg')})`)},[_c('div',{staticClass:"overlay"}),_c('div',{class:[
      'home-golf',
      'main-layout-min-height',
      'has-news'
    ]},[_c('div',{staticClass:"bg-cover"}),_c('div',{staticClass:"content"},[_vm._m(0),(_vm.filteredNewsTicker.length)?_c('div',{staticClass:"news-ticker"},[_vm._m(1),_c('div',{staticClass:"body"},[_c('div',{staticClass:"address"},_vm._l((_vm.filteredNewsTicker),function(item,index){return _c('p',{key:`${item.identification}${index}`,staticClass:"information ql-editor",domProps:{"innerHTML":_vm._s(item.information)}})}),0)])]):_c('div',[_c('h5',[_vm._v(" NO Data ")]),_c('router-link',{attrs:{"to":"/admin/news"}},[_vm._v(" GO BACK ")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"first"},[_c('h1',{staticClass:"title"},[_vm._v(" Adventure Golf hat nicht viel mit dem „altbekannten“ Minigolf zu tun und ist dem „großen Golf“ sehr ähnlich. ")]),_c('h3',{staticClass:"sub-title"},[_vm._v(" Unsere Anlage verkörpert unsere Region mit unserer Philosophie - dem regionalen Sport. ")]),_c('button',{staticClass:"base-button"},[_vm._v(" ERLEBE EINE TOLLE ZEIT - JETZT RESERVIEREN ")]),_c('div',{staticClass:"features"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"detail"},[_c('h5',{staticClass:"big"},[_vm._v(" 18 ")]),_c('p',{staticClass:"small"},[_vm._v(" Bahnen ")])])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"detail"},[_c('h5',{staticClass:"big"},[_vm._v(" 3.500 ")]),_c('p',{staticClass:"small"},[_vm._v(" m2 ")])])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"detail"},[_c('h5',{staticClass:"big"},[_vm._v(" 100% ")]),_c('p',{staticClass:"small"},[_vm._v(" Fun ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-ticker-title"},[_c('span',{staticStyle:{"color":"#000"}},[_vm._v("+++")]),_vm._v(" "),_c('span',{staticStyle:{"color":"#000"}},[_vm._v("NEWS")]),_vm._v("TICKER"),_c('span',{staticStyle:{"color":"#000"}},[_vm._v(" +++")])])
}]

export { render, staticRenderFns }